import React, { Component } from "react";
import styles from "./ContactInfoPage.module.css";
import contactPicture from "./images/KontaktInfo_transparent.png";
import Header from "./Header";
export default class ContactInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showImpressum: false,
    };
  }

  toggleImpressum = () => {
    this.setState((prevState) => ({
      showImpressum: !prevState.showImpressum,
    }));
  };

  render() {
    const { showImpressum } = this.state;

    return (
      <div className={styles.lastPage} >
        <div className={styles.kontaktInfo}>
          <img src={contactPicture} alt="Kontaktinfo"></img>
          <div className={styles.impressum} onClick={this.toggleImpressum}>
            <h3>&raquo;Impressum&laquo;</h3>
          </div>

          {showImpressum && (
            <div className={styles.impressumWindow}>
              <div className={styles.impressumWindow_impressum}>
                <Header>IMPRESSUM</Header>
                <p>
                  Pflegeaktiv24 <br></br>
                  Inhaber: Feurstein Thomas<br></br>
                  Kehlermähder 41<br></br>
                  6850 Dornbirn<br></br>
                  Telefon: +43 (0)699 18165964<br></br>
                  E-Mail: office@pflegeaktiv24.at<br></br>
                  Internet: www.pflegeaktiv24.at<br></br>
                  Steuernummer: ATU65791547<br></br>
                  Firmenbuchnummer: fn366118i<br></br>
                  Vertretungsberechtigter: Feurstein Thomas<br></br>
                  Inhaltlich Verantwortlicher gemäß §55 Abs. 2 RStV: Feurstein
                  Thomas<br></br>
                </p>
              </div>
              <div className={styles.impressumWindow_haftungshinweis}>
                <Header>HAFTUNGSHINWEIS</Header>
                <p>
                  Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
                  Haftung für die Inhalte externer Links. Für den Inhalt der
                  verlinkten Seiten sind ausschließlich deren Betreiber
                  verantwortlich.
                </p>
              </div>
              <div
                className={styles.closeButton}
                onClick={this.toggleImpressum}
              >
                Schließen
              </div>
            </div>
          )}
        </div>

        <p className={styles.copyRight}>&#169; 2024 www.pflegeaktiv24.at </p>
      </div>
    );
  }
}
