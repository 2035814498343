import React, { useState, useEffect } from 'react';
import ApplyPage from "./ApplyPage.js";
import styles from "./ApplyBox.module.css";


const ApplyBox = () => {

  const [showApplyPage, setShowApplyPage] = useState(false);

  const openApplyPage = () => {
    setShowApplyPage(true);
  };

  const closeApplyPage = () => {
    setShowApplyPage(false);
  };

  return (
    
    <div className={styles.applyContainer} >

      <h2>Bewirb dich JETZT!</h2>
      <p>
        Hatten Sie schon immer Interesse an der 24 Stunden Betreuung? Wollten
        Sie schon immer im sozialen Bereich arbeiten? Dann sind Sie bei
        uns richtig! Bewerben Sie sich jetzt ganz einfach über unsere
        Website!
      </p>
      <button type="button" onClick={openApplyPage} className={styles.applyBoxButton}>
        Ich möchte mich bewerben
      </button>

      {showApplyPage && (
        <ApplyPage
          onClose={closeApplyPage}
        />
      )}

    </div>
  );
};

export default ApplyBox;
