// Header.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = ({ children }) => {
  useEffect(() => {
    AOS.init({ duration: 500 }); 
  }, []);
  return( <h1 style={{ fontSize: "3vh", textAlign:"center", color:"#80358c",margin:30 }} data-aos="flip-up">{children}</h1>);
};


export default Header;
