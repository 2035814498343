import React, { Component } from "react";
import styles from "./ContactMePage.module.css";
import MyPicture from "./images/Thomas-Feurstein_SchattenEffekt.png";
import { sendEmail } from "./SendQuestionEmail.js";
import Datenschutz from "./images/Datenschutz.pdf";
import ApplyBox from "./ApplyBox.js";
import Header from "./Header.js";
import { ReCaptchaComponent, getCapVal } from "./ReCaptchaComponent.js";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class ContactPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      checkboxChecked: false,
      nameError: "",
      emailError: "",
      messageError: "",
      checkboxError: "",
    };
  }

  componentDidMount() {
    AOS.init({ duration: 2000 }); 
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      checkboxChecked: !prevState.checkboxChecked,
      checkboxError: "",
    }));
  };

  handleSendMessageClick = () => {
    const { name, email, message, checkboxChecked } = this.state;

    // Überprüfe, ob alle Felder ausgefüllt sind und die Checkbox angeklickt wurde
    let isValid = true;

    if (!name) {
      this.setState({ nameError: "Bitte geben Sie Ihren Namen ein." });
      isValid = false;
    } else {
      this.setState({ nameError: "" });
    }

    if (!email) {
      this.setState({ emailError: "Bitte geben Sie Ihre E-Mail-Adresse ein." });
      isValid = false;
    } else {
      this.setState({ emailError: "" });
    }

    if (!message) {
      this.setState({ messageError: "Bitte geben Sie Ihre Nachricht ein." });
      isValid = false;
    } else {
      this.setState({ messageError: "" });
    }

    if (!checkboxChecked) {
      this.setState({
        checkboxError: "Bitte akzeptieren Sie die Datenschutzbestimmungen.",
      });
      isValid = false;
    } else {
      this.setState({ checkboxError: "" });
    }

    if (isValid) {
      const capVal = getCapVal();
      if (capVal) {
        // Rufe sendEmail auf, wenn alles in Ordnung ist
        sendEmail(name, email, message);
      } else {
        alert("Bitte die Überprüfung abschließen!");
      }
    }
  };

  render() {
    const {
      nameError,
      emailError,
      messageError,
      checkboxError,
      name,
      email,
      message,
    } = this.state;

    return (
      <div id="fifth-page" className={styles.FifthPage}>
        <div className={styles.mainContainer}>
          <div className={styles.aboutMe}>
            <div className={styles.myPicture}>
              <img src={MyPicture} alt="My Picture" />
            </div>
            <div className={styles.myNameAndRole}>
              <p className={styles.MyName}>~THOMAS FEURSTEIN</p>
              <p className={styles.MyRole}> Geschäftsführung </p>
            </div>
          </div>

          <div className={styles.contactMe}>
            <div className={styles.kontakt}>
              <h3 style={{ fontSize: "1.5em" }}>
                Haben Sie Fragen?<br></br>
              </h3>
              <Header>Wir freuen uns darauf, Sie kennenzulernen</Header>
            </div>

            <div className={styles.nameInput}>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="NAME"
                value={name}
                onChange={this.handleInputChange}
              />
              <div className={styles.errorText}>{nameError}</div>
            </div>
            <div className={styles.emailInput}>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="EMAIL"
                value={email}
                onChange={this.handleInputChange}
              />
              <div className={styles.errorText}>{emailError}</div>
            </div>
            <div className={styles.messageInput}>
              <textarea
                type="text"
                id="message"
                name="message"
                placeholder="NACHRICHT"
                value={message}
                onChange={this.handleInputChange}
              />
              <div className={styles.errorText}>{messageError}</div>
            </div>
            <div className={styles.datenschutzContainer}>
              <label>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  onChange={this.handleCheckboxChange}
                ></input>
                <span className={styles.datenschutzText}>
                  Ich bin damit einverstanden, dass meine personenbezogenen
                  Daten zur Bearbeitung dieser Anfrage verarbeitet werden
                  dürfen. Mehr Informationen finden Sie in unseren
                  <a
                    href={Datenschutz}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Datenschutzbestimmungen.
                  </a>
                </span>
              </label>
            </div>

            <div className={styles.errorText}>{checkboxError}</div>
            <div style={{ marginTop: "7vh" }}>
              <ReCaptchaComponent from={name} email={email} message={message} />
            </div>
            <div className={styles.buttonSend}>
              <button type="button" onClick={this.handleSendMessageClick}>
                Nachricht senden
              </button>
            </div>
          </div>
        </div>
        <div className={styles.myPhoneBox} data-aos="flip-up">
          <p>Wir sind auch telefonisch für Sie erreichbar!</p>
          <span>
            &#9990; 05572 21621
            <br></br>
            &#9990; 0699 18165964
            <br></br>
            &#9990; 0677 61385768
          </span>
          <div className={styles.underline}></div>
        </div>
        <ApplyBox />
      </div>
    );
  }
}
