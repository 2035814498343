import React, { Component } from "react";
import styles from "./TitlePage.module.css";
import LogoPicture from "./images/Pflegeaktiv24-Logo.png";

export default class TitlePage extends Component {
  render() {
    return (
      
        <div className={styles.main}>
 
          <div>
            <img
              src={LogoPicture}
              alt="Hände"
              className={styles.logo}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            ></img>
          </div>
          <div className={styles.hauptueberschrift}>
            <h1 data-aos="zoom-in-up">
              Ihre Lebensqualität ist uns <br />
              <span className={styles.word}> wichtig</span>
            </h1>
          </div>
          <div className={styles.kreisOverlay} />
          <h1 className={styles.h1_KreisOverlay}>
            Ihre Lebensqualität ist uns wichtig
          </h1>
          <div className={styles.underline}></div>
        </div>
      
    );
  }
}
