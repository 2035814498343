import React, { useEffect } from "react";
import styles from "./HoverInfoPage.module.css";
import PictureOne from "./images/AdobeStock_264597255.jpeg";
import PictureTwo from "./images/AdobeStock_241429777.jpeg";
import PictureThree from "./images/fotolia_211647114.jpg";
import Header from "./Header";

import AOS from 'aos';
import 'aos/dist/aos.css';

const HoverInfoPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); 
  }, []);

  return (
    <div id="second-page" className={styles.secondPage}>
      <div className={`${styles.titlePageTwo}`}>
        <span data-aos="fade-right"><Header>Zuhause leben</Header></span>
      </div>
      <div className={`${styles.pictures}`}>
        <div className={styles.pictureContainer} data-aos="fade-right">
          <img src={PictureOne} alt="PictureOne-Fürsorge" />
          <div className={styles.imageText}>Fürsorge</div>
          <div className={styles.imageBackgroundText}>
            Die Betreuung Zuhause stellt eine oft enorme Herausforderung für
            alle Beteiligten dar: Die bestmögliche individuelle Pflege ist
            aufgrund der gesundheitlichen und familiären Situation sowie den
            familiären und finanziellen Voraussetzungen mitunter schwer zu
            organisieren.
          </div>
        </div>
        <div className={styles.pictureContainer} data-aos="fade-left">
          <img src={PictureTwo} alt="PictureTwo-Betreuung" />
          <div className={styles.imageText}>Betreuung</div>
          <div className={styles.imageBackgroundText}>
            <span>
              <h3>WIR WISSEN WAS BETREUUNG BEDEUTET</h3>
            </span>
            Wir arbeiten mit ausgesuchtem Personal, aber vor allem mit
            MitarbeiterInnen, die über ein hohes Maß an Menschlichkeit
            verfügen und Erfahrung im Umgang mit alten oder kranken Menschen
            haben.
          </div>
        </div>
        <div className={styles.pictureContainer} data-aos="fade-up">
          <img
            src={PictureThree}
            alt="PictureThree-Wohlbefinden"
            className={styles.pictureThree}
          />
          <div className={styles.imageText}>Wohlbefinden</div>
          <div className={styles.imageBackgroundText}>
            <span>
              <h3>Wir sind seit 2009 für Sie da und von Anfang an dabei!</h3>
            </span>
            Ihre Lebensqualität bedeutet für uns nicht nur, dass Sie gut
            versorgt sind, sondern auch, dass wir mit Wärme, Mitgefühl und
            Menschlichkeit zur Seite stehen. Allem voran ermöglichen wir
            Ihnen unter Ausschöpfung sämtlicher Ressourcen ein würdevolles
            Leben in gewohnter Umgebung.
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoverInfoPage;
