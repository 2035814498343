// In ReCaptchaComponent.js
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";


let capVal = null;  // Außerhalb der Komponente definieren

export function ReCaptchaComponent() {
  const handleCaptchaChange = (value) => {
    capVal = value;  // Den Wert in der außerhalb definierten Variable speichern
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey="6LfQjHopAAAAAJ2bImXf05db0egfr7UQgQFeZQLJ"
        onChange={handleCaptchaChange}
      />
    </div>
  );
}

// Funktion außerhalb der Komponente definieren
export const getCapVal = () => {
  
  return capVal;
};
